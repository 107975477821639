import * as Sentry from "@sentry/nuxt"

const config = useRuntimeConfig().public

Sentry.init({
  dsn: config.sentryDsn,
  release: config.sentryReleaseName,
  environment: config.environment,
  tracesSampleRate: 1.0,
})
